import React from "react";
import Layout from "../components/_App/layout";
import NavbarStyleThree from "../components/_App/NavbarStyleThree";
import Footer from "../components/_App/Footer";
import PageBanner from '../components/Common/PageBanner';
import Aboutus from "../assets/getwidget/aboutus.png";
import ReactMarkdown from "react-markdown";
import { graphql, StaticQuery } from "gatsby";
import SEO from '../components/_App/seo';
import { getImageURL } from "../format-date";
const GET_ABOUT_US_INFORMATION = graphql`
query GetAboutUsInformation {
    strapiAboutUs {
      description
      seo_description
      seo_image {
        localFile {
          name
          extension
        }
      }
      seo_title
    }
  }
  
`
const About1 = () => (
    <StaticQuery query={GET_ABOUT_US_INFORMATION} render={data => (
        <Layout>
            <head></head>
            <SEO title={data.strapiAboutUs.seo_title} type="website" description={data.strapiAboutUs.seo_description} image={getImageURL(data.strapiAboutUs.seo_image.localFile.name, data.strapiAboutUs.seo_image.localFile.extension)} url="/about-us/" />
            <NavbarStyleThree />
            {/* <PageBanner pageTitle="About Us" /> */}
            <div>
                <img src="https://ik.imagekit.io/ionicfirebaseapp/getwidget/gatsby-marketplace/About_us_PTSLwhQ8G.png?updatedAt=1640669734163" className="img-fluid banner-img"/>
                {/* <img src={Aboutus} className="img-fluid banner-img"/> */}
                </div>
            <div className="about-area ptb-80 pt-55">
                <div className="container-fluid padd-2">
                <div className="section-title">
                <h2>About Us</h2>
                                    <div className="bar"></div>
                                </div>
                    <div className="row align-items-center">
                        {/* <div className="col-lg-6 col-md-12">
                            <div className="about-image">
                                <img src="https://image.ionicfirebaseapp.com/gatsby-marketplace/contact-img_YNGHDUJYl.png?updatedAt=1640329267262" alt="image" />
                            </div>
                        </div> */}
                        <div className="col-lg-12 col-md-12">
                            <div className="about-content">
                                <div className="section-title">
                                   
                                    {/* <p>Lorem ipsum dolor sit amet, con se ctetur adipiscing elit. In sagittis eg esta ante, sed viverra nunc tinci dunt nec elei fend et tiram.</p> */}
                                </div>

                                <ReactMarkdown children={data.strapiAboutUs.description} />
                            </div>
                        </div>
                    </div>

                    {/* <div className="about-inner-area">
                    <div className="row justify-content-center">
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="about-text">
                                <h3>Our History</h3>
                                <p>Lorem ipsum dolor sit amet, con se ctetur adipiscing elit. In sagittis eg esta ante, sed viverra nunc tinci dunt nec elei fend et tiram.</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="about-text">
                                <h3>Our Mission</h3>
                                <p>Lorem ipsum dolor sit amet, con se ctetur adipiscing elit. In sagittis eg esta ante, sed viverra nunc tinci dunt nec elei fend et tiram.</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="about-text">
                                <h3>Who We Are</h3>
                                <p>Lorem ipsum dolor sit amet, con se ctetur adipiscing elit. In sagittis eg esta ante, sed viverra nunc tinci dunt nec elei fend et tiram.</p>
                            </div>
                        </div>
                    </div>
                </div> */}
                </div>
            </div>

            {/* <Team /> */}

            {/* <Partner /> */}

            {/* <FunFactsArea /> */}

            <Footer />
        </Layout>
    )}></StaticQuery>
)

export default About1;
